<template>
  <div v-loading="loading">
    <GMapMap
        ref="gmap"
        :center="center"
        :zoom="12"
        style="width: 100%; height: 500px"
    >
      <GMapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :label='{text: m.id, color: "white"}'
          @click="openMarker(m.id)"
      >
        <GMapInfoWindow
            :closeclick="true"
            @closeclick="openMarker(null)"
            :opened="openedMarkerID === m.id"
        >
          <div>ID : {{m.id}}</div>
          <div>Name : {{m.name}}</div>
          <div>Mobile : {{m.mobile}}</div>
        </GMapInfoWindow>
      </GMapMarker>
    </GMapMap>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, inject} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {checkPermission} from "@/core/helpers/general";
import {initializeApp} from 'firebase/app';
import {getFirestore, collection, getDocs} from 'firebase/firestore/lite';
import {getDatabase, ref as refFirebase, onValue} from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {APIs} from "@/store/enums/ApiEnums";
import {handleError} from "@/core/helpers/response";

export default defineComponent({
  name: "providers-location",
  components: {},


  setup() {
    const providerIds = ref([]);
    const loading = ref(false);
    const markers = ref([]);
    const openedMarkerID = ref(null);
    const center = ref({lat: 29.33628, lng: 47.67905});
    const firebaseConfig = inject('firebaseConfig')
    const app = initializeApp(firebaseConfig);
    const db = getDatabase(app);
    const auth = getAuth();
    const starCountRef = refFirebase(db, 'drivers_online');
    const firebaseToken = ref(null);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Providers Location", []);
    });


    const getProvidersLocation = () => {

      signInWithCustomToken(auth, firebaseToken.value)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            onValue(starCountRef, (snapshot) => {
              const data = snapshot.val();
              console.log(data);
              if(data) {
                let providersLocation = [];
                let index = 0;
                Object.keys(data).forEach(key => {

                  providersLocation.push({
                    id : data[key].Location.driverId.replace("provider-",""),
                    name : data[key].Location.driverName,
                    mobile : data[key].Location.driverMobile,
                    position: {
                      lat: parseFloat(data[key].Location.latitude), lng: parseFloat(data[key].Location.longitude)
                    },
                  })
                  index++;

                });
                markers.value = providersLocation.filter(el => providerIds.value.find(el2 => el2 == el.id));
                if (markers.value.length > 0) center.value = {lat: parseFloat(markers.value[0].position.lat), lng: parseFloat(markers.value[0].position.lng)};

              }

            });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage);
          });


    }
    loading.value = true;
    ApiAxiosService.get(APIs.PROVIDER.location).then(function (res){
      providerIds.value = res.data.data.provider_ids;
      firebaseToken.value = res.data.data.firebase_token;
      getProvidersLocation();
      loading.value = false;
    }).catch(function (e){
      handleError(e)
      loading.value = true;
    });
    const openMarker = (id) => {
      openedMarkerID.value = id;
    }

    return {
      getIllustrationsPath,
      markers,
      center,
      openedMarkerID,
      openMarker,
      loading
    };
  },

});
</script>
